import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useParams } from "react-router";
import { Container, Row, Col, Image, Card, Button, CardGroup } from "react-bootstrap"; // Notice doesn't matter you have imported already Container in App.js
// But the css import in App.js WORKS



const Listing = (props) => {

    let { id } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [listing, setListing] = useState([]);

    const [listingTitle, setListingTitle] = useState([]);
    const [listingPrice, setListingPrice] = useState([]);

    // fetch("https://jsonplaceholder.typicode.com/users/" + id)
    // fetch("https://api.offeraki.com/v1/listings/listing/<guid>")
    // fetch("http://localhost:3002/v1/listings/listing/" + id)

    useEffect(() => {
        fetch("https://api.offeraki.com/v1/listings/listing/" + id)
            .then(res => res.json())
            .then(
                (data) => {
                    console.log(data);
                    setListing(data.with);
                    setIsLoaded(true);
                    setListingTitle(data.title);
                    setListingPrice(data.price);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (listing) {
        return (
            <div>
                {/* Use Layout Grid https://react-bootstrap.github.io/layout/grid/ Responsive grids*/}
                <Container>
                    <Row className="px-4 my-5">
                        <Col sm={7}>
                            <Image src={listing.images[0].url} fluid rounded className="" />
                        </Col>
                        <Col sm={5}>
                            <h1 class="font-weight-light">{listing.title}</h1>
                            <h2 class="">${listing.price} USD</h2>
                            <p>
                                {listing.description}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Card className="text-center bg-secondary text-white my-5 py-4">
                            <Card.Body>
                                Images
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row>
                        {listing.images.map(image => (
                            <Col>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img variant="top" src={image.url} />
                                    {/*
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                            Some quick example text to build on the card title and make up the bulk of
                                            the card's content.
                                        </Card.Text>
                                        <Button variant="primary">Go somewhere</Button>
                                    </Card.Body>
                                    */}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>

                {/*
                <h1>{listing.title}</h1>
                <div>
                    Title: {listing.title}
                </div>
                <div>
                    Description: {listing.description}
                </div>
                <div>
                    Price: {listing.price}
                </div>
                <div>
                    <img src={listing.images[0].url} width="193" height="130" alt={listing.title} />
                </div>
                */}

            </div>
        );
    }
}
export default Listing;


/*
import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useParams } from "react-router";

const Listing = (props) => {

    let { id } = useParams();

    return (
        <div>
            <h1>Listing Details</h1>
            ID: {id}
        </div>
    );
}
export default Listing;
*/

/*
import React from 'react';
const Listing = (props) => {

    var id = props.match.params.id

    return (
        <div>
            <h1>Listing Details</h1>
            ID: {id}
        </div>
    );
}
export default Listing;

*/

/*
import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useParams } from "react-router";

const Listing = (props) => {

    let { id } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [user, setUser] = useState([]);

    const [userAddress, setUserAddress] = useState([]);
    const [userCompany, setUserCompany] = useState([]);

    useEffect(() => {
        fetch("https://jsonplaceholder.typicode.com/users/" + id)
            .then(res => res.json())
            .then(
                (data) => {
                    console.log(data);
                    setUser(data);
                    setIsLoaded(true);
                    setUserAddress(data.address);
                    setUserCompany(data.company);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (user) {
        return (
            <div>
                <h1>{user.name}</h1>
                <div>
                    Email: {user.email}
                </div>
                <div>
                    Phone: {user.phone}
                </div>
                <div>
                    Website: {user.website}
                </div>
                <div>
                    Company: {userCompany.name}
                </div>
                <div>
                    Address: {userAddress.street}, {userAddress.suite},  {userAddress.city}, {userAddress.zipcode}
                </div>
            </div>
        );
    }
}
export default Listing;


*/