import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Card, Button, CardGroup } from "react-bootstrap"; // Notice doesn't matter you have imported already Container in App.js

const Listings = () => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    // const [users, setUsers] = useState([]);
    const [listings, setListings] = useState([]);
    // fetch("https://jsonplaceholder.typicode.com/users/")
    // fetch("https://api.offeraki.com/v1/listings")
    // <Link to={`listing/${listing._id}`}>{listing.title}</Link><img src={`listing.images[0].thumbUrl`} />
    //         fetch("http://localhost:3002/v1/listings")
    // localhost:3002/v1/listings?sortOrder=title_desc&searchString=Test for something&pageIndex=0&pageSize=11&lat=42.2338015&lon=-88.1559741&query=&radius=80000
    // console.log("Localhost http://localhost:3002/v1/listings");
    useEffect(() => {
        // fetch("http://localhost:3002/v1/listings?&pageIndex=0&pageSize=50")
        // fetch("http://localhost:3002/v1/listings?&pageIndex=2&pageSize=5")
        // fetch("http://localhost:3002/v1/listings?&pageIndex=0&pageSize=30") // Works very well
        // fetch("https://api.offeraki.com/v1/listings")
        fetch("https://api.offeraki.com/v1/listings?&pageIndex=0&pageSize=50")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setListings(data.with);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <Container fluid>
                <Row className="px-4 my-5">
                    <CardGroup>
                        {listings.map(listing => (
                            <Col>
                                <Card style={{ width: '18rem' }} key={listing.guid}>
                                    <Link to={`listing/${listing.guid}`}> <Card.Img variant="top" src={listing.images[0].url} /> </Link>
                                    <Card.Body>
                                        <Card.Title><Link to={`listing/${listing.guid}`}>{listing.title}</Link></Card.Title>
                                        <Card.Text>
                                            ${listing.price} USD
                                        </Card.Text>
                                        <Link to={`listing/${listing.guid}`}><Button variant="primary">Details</Button></Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </CardGroup>
                </Row>
            </Container>
        );
    }
}

export default Listings;


/*

        return (
            <ul>
                {listings.map(listing => (
                    <li>
                        <Link to={`listing/${listing.guid}`}>{listing.title}<img src={listing.images[0].url} width="193" height="130" alt={listing.title} /></Link>
                        <div>{listing.images[0].url}</div>
                    </li>
                ))}
            </ul>
        );


        return (
            <ul>
                {listings.map(listing => (
                    <li>
                        <Link to={`listing/${listing.guid}`}>{listing.title}</Link><img src={listing.images[0].url} width="193" height="130" alt={listing.title} />
                        <div>{listing.images[0].url}</div>
                    </li>
                ))}
            </ul>
        );
*/


/* 
import React from 'react';
const Listings = () => {
    return (
        <div>
            <h1>Listings</h1>
        </div>
    );
}
export default Listings;
*/