import React from 'react';
// This is a React Router v6 app
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from "react-router-dom";

import Listings from './listings';
import Listing from './listing';

const Webpages = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Listings />} />
                <Route path="listing/:id" element={<Listing />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Webpages;
/*
import React from 'react';
// This is a React Router v6 app
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from "react-router-dom";

import Listings from './listings';
import Listing from './listing';

const Webpages = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" component={Listings} />
                <Route path="/listing" component={Listing} />
            </Routes>
        </BrowserRouter>
    );
};

export default Webpages;
*/