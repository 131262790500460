import React from 'react';
import Webpages from './webpages';
import { Navbar, Container, Nav, Row, Col, Alert, Image, NavDropdown } from "react-bootstrap"; // react-bootstrap contains only JS
import "bootstrap/dist/css/bootstrap.min.css"; // this is the css
import logo from './logo-round.png'; // Tell webpack this JS file uses this image
import AppleStoreButton from './apple-store-button.svg';
import GooglePlayButton from './google-play-button.svg';

// add Navbar from in App.js
// https://react-bootstrap.github.io/components/navbar/

function App() {
  return (
    <div>

      { /* Bootstrap Navbar  https://react-bootstrap.github.io/components/navbar/ */}
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">Offeraki <img src={logo} alt="Logo" width="50" height="50" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/*
              <Nav.Link href="/">Home</Nav.Link>
              
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
              </NavDropdown>
              */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        <Row>
          <Col>
            <Alert key="primary" variant="primary" style={{ marginTop: 10 }}>
              <h1>
                Download the application
                &nbsp;
                <Alert.Link href="https://apps.apple.com/us/app/offeraki/id1587941057"><Image src={AppleStoreButton} fluid={true} style={{ width: 115 }} /></Alert.Link>
                &nbsp;
                <Alert.Link href="https://play.google.com/store/apps/details?id=com.offeraki"><Image src={GooglePlayButton} fluid={true} style={{ width: 130 }} /></Alert.Link>
              </h1>
              <p>Access local deals, garage sale events, and used items. Make money selling your stuff or promote your business!</p>
            </Alert>
          </Col>
        </Row>
      </Container>

      { /* Main part comming from the pages */}
      <main>
        <Webpages />
      </main>

      { /*  Footer */}
      <footer class="py-5 my-5 bg-dark">
        <Container className="px-4">
          <p class="text-center text-white">Copyright &copy; Offeraki 2022</p>
        </Container>
      </footer>

    </div>
  );
}
export default App;


/* // The following code works
import React from "react";
// This is a React Router v6 app
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import Listings from './webpages/listings';
import Listing from './webpages/listing';

function App() {
  return (
    <BrowserRouter>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>
            <Link to="/listings">Listings</Link>
          </li>
          <li>
            <Link to="/listing">Listing</Link>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="users/*" element={<Users />} />
        <Route path="about/*" element={<About />} />
        <Route path="listings/*" element={<Listings />} />
        <Route path="listing/*" element={<Listing />} />
      </Routes>
    </BrowserRouter>
  );
}


// function Users() {
//   return (
//     <div>
//       <nav>
//         <Link to="me">My Profile</Link>
//       </nav>

//       <Routes>
//         <Route path=":id" element={<UserProfile />} />
//         <Route path="me" element={<OwnUserProfile />} />
//       </Routes>
//     </div>
//   );
// }

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export default App;
*/


// This doesn't work
// import React from 'react';
// import Webpages from './webpages';
// import {
//   BrowserRouter,
//   Routes,
//   Route,
//   Link,
// } from "react-router-dom";

// import Listings from './webpages/listings';
// import Listing from './webpages/listing';

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" component={Listings} />
//         <Route path="/listing" component={Listing} />
//       </Routes>
//     </BrowserRouter>

//   );
// }


// export default App;